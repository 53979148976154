import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import CustomTableHeader from './CustomTableHeader';
import CustomTableToolbar from './CustomTableToolbar';
import CustomTableRow from './CustomTableRow';
import { getComparator } from '../../../util/tableUtils';
import { createTheme } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import GeneralSwipeableView from '../ContentContainers/GeneralSwipeableView';
import { filterData } from '../../../util/filters';

const theme_bp = createTheme({
  breakpoints: {
    values: {
      sm: 750
    },
  },
});

const useStyles = makeStyles({
  filterRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  bigTable: {
    [theme_bp.breakpoints.down('sm')]: {
      display: "none",
    },
  },
  smallTable: {
    [theme_bp.breakpoints.up('sm')]: {
      display: "none",
    },
    [theme_bp.breakpoints.down('sm')]: {
      display: "flex",
      flexDirection: "column"
    },
  },
  smallTableRows: {
    display: "flex",
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
export default function CustomTableContainer(props) {
  const { defaultOrder, defaultOrderHeader, headers, rowData, paginationOptions, defaultPaginationOption, clickAction, initialFilters, getMobileElement, swipeable, showArchived, checkBoxes, handleArchivedToggle, AdditionalToolbar, actions, bulkUpdate, setBulkUpdate, actionsHistory, setActionsHistory, canUndo, canRedo, handleUndoClick, handloRedoClick, handleActionClick, initialStatuses, selected, setSelected, selectAll, setSelectAll, UnsavedChangesSnackbar, isSelected, ActionsButtons, ButtonFilters, buttonRanking } = props;

  const [order, setOrder] = useState(defaultOrder); // asc or desc
  const [orderBy, setOrderBy] = useState(defaultOrderHeader);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultPaginationOption);
  const [visibleRows, setVisibleRows] = useState([]);
  const [filters, setFilters] = useState(initialFilters || []);
  const [filteredRows, setFilteredRows] = useState([]);
  const [visibleHeaders, setVisibleHeaders] = useState(headers);

  const classes = useStyles();

  const updateHeaders = (newHeaders) => {
    console.log(newHeaders);
    setVisibleHeaders(newHeaders);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    if (selectAll) {
      setSelectAll(false);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateFilters = (modifiedFilters) => {
    setFilters(modifiedFilters);
  }

  const handleSelectAllClick = (event) => {
    event.stopPropagation();
    setSelectAll(!selectAll);
    if (event.target.checked) {
      const newSelected = visibleRows.map((n) => n.id).filter((id) => !selected.includes(id));
      setSelected([...selected, ...newSelected]);
      return;
    }
    setSelected(selected.filter((id) => !visibleRows.map((n) => n.id).includes(id)));
  }

  const handleOnSelectClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  useEffect(() => {
    setOrderBy(defaultOrderHeader)
  }, [defaultOrderHeader]);

  useEffect(() => {
    setVisibleHeaders(headers)
  }, [headers]);

  useEffect(() => {
    if (initialFilters) {
      let updatedInitialFilters = [...initialFilters]
      updatedInitialFilters.push({ header: { id: "insight_status", type: "BUTTONS" }, value: "archive" })
      setFilters(updatedInitialFilters)
    }
  }, [initialFilters]);

  useEffect(() => {
    if (filteredRows) {
      setVisibleRows(
        filteredRows.sort(getComparator(order, orderBy, visibleHeaders, actions, buttonRanking)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage,
        )
      );
    }
  }, [order, orderBy, page, rowsPerPage, filteredRows, actions]);

  useEffect(() => {
    if (initialFilters) {
      setFilters(initialFilters);
    }
  }, [initialFilters]);

  function updateFilteredRows() {
    let rows = rowData;
    if (filters) {
      rows = filterData(filters, rows, actions);
    }

    if (showArchived === false) {
      rows = rows.filter((row) => actions[row.id] !== "archive");
    }

    setFilteredRows(rows);
  }

  useEffect(() => {
    if (rowData) {
      updateFilteredRows();
      setPage(0);
    }
  }, [rowData, filters, showArchived]);

  useEffect(() => {
    if (actions) {
      localStorage.setItem('actions', JSON.stringify(actions));

      if (rowData && actions) {
        // auto sort after actions change
        updateFilteredRows();
      }
  
      if (actionsHistory && actionsHistory.length === 0 && actions && actions.length > 0) {
        setActionsHistory([actions]);
      }    
    }
  }, [actions]);

  return (
    <div>
      <Paper sx={{ width: '100%' }}>
        <CustomTableToolbar
          headers={visibleHeaders}
          rowData={rowData}
          filters={filters}
          filteredRowData={filteredRows}
          updateFilters={updateFilters}
          bulkUpdate={bulkUpdate}
          setBulkUpdate={setBulkUpdate}
          selectedRows={selected}
          setSelectedRows={setSelected}
          onActionClick={handleActionClick}
          actions={actions}
          initialStatuses={initialStatuses}
          canRedo={canRedo}
          canUndo={canUndo}
          onUndoClick={handleUndoClick}
          onRedoClick={handloRedoClick}
          setSelectAll={setSelectAll}
          handleArchivedToggle={handleArchivedToggle}
          showArchived={showArchived}
          AdditionalToolbar={AdditionalToolbar}
          ButtonFilters={ButtonFilters}
        />
        <div className={classes.bigTable}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={'small'}
            >
              <CustomTableHeader
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headers={visibleHeaders}
                updateHeaders={updateHeaders}
                onSelectAllClick={handleSelectAllClick}
                selectAll={selectAll}
                checkBoxes={checkBoxes}
              />
              <TableBody>
                {visibleRows.map((row, index) => (
                  <CustomTableRow
                    row={row}
                    headers={visibleHeaders}
                    clickAction={clickAction}
                    isSelected={isSelected}
                    onSelectClick={handleOnSelectClick}
                    checkBoxes={checkBoxes}
                    ActionsButtons={ActionsButtons}
                  />))}
              </TableBody>
            </Table>
            {UnsavedChangesSnackbar}
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={paginationOptions}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            showFirstButton={true}
            showLastButton={true}
          />
        </div>
        {getMobileElement && <div className={classes.smallTable}>
          {swipeable ?
            (<>
              <GeneralSwipeableView content={filteredRows.map((row, index) => (getMobileElement(row)))} />
            </>) :
            (<>
              <div className={classes.smallTableRows}>
                {visibleRows.map((row, index) => (getMobileElement(row)))}
              </div>
              <TablePagination
                rowsPerPageOptions={[defaultPaginationOption]}
                component="div"
                count={filteredRows.length}
                rowsPerPage={defaultPaginationOption}
                page={page}
                onPageChange={handleChangePage}
              />
            </>)
          }

        </div>}

      </Paper>
    </div>
  );
}