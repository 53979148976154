import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

import { useLazyLoadSelector } from '../../util/redux';
import { fetchDeployedModel, fetchModels } from '../../actions/deviceActions';
import Integrations from '../Admin/Integration/Integrations';
import TabContentContainer from '../Common/ContentContainers/TabContentContainer';
import HomePageWrapper from '../Common/PageWrappers/HomePageWrapper';
import Features from '../Admin/Feature/Features';
import { fetchUserFeatures } from '../../actions/featureActions';

const styles = (theme) => ({
  toolbar: theme.mixins.toolbar
});

const Admin = (props) => {
  const { classes } = props;

  useLazyLoadSelector((state) => state.feature.features, fetchUserFeatures, [], false);

  const tabs = [
    {
      label: "Integrations",
      content: (<Integrations/>)
    }
  ];

  useLazyLoadSelector((state) => state.device.deviceModelData, fetchModels, [], false);
  useLazyLoadSelector((state) => state.device.deployedModelData, fetchDeployedModel, [], false);

  return (
    <HomePageWrapper>
      <TabContentContainer tabs={tabs}/>
    </HomePageWrapper>
  );
};

export default withStyles(styles)(Admin);
