import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  withStyles,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import RouterIcon from '@material-ui/icons/SettingsRemote';
import MailIcon from '@material-ui/icons/Mail';
import WifiIcon from '@material-ui/icons/Wifi';

import ItemList from '../../Common/ItemList';
import IntegrationItem from './IntegrationItem';
import { IntegrationPlatforms } from '../../../util/constants';
import { fetchUserIntegrations } from '../../../actions/integrationActions';
import RequestSnackbar from '../../Common/snackbars/RequestSnackbar';
import ErrorSnackbar from '../../Common/snackbars/ErrorSnackbar';
import { fetchModelClasses } from '../../../actions/modelActions';

const styles = (theme) => ({
  integrationInfoCard: {
    [theme.breakpoints.up('md')]: {
      width: '50%',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '20px',
    marginTop: theme.spacing(2),
    padding: 0,
  },
  cardContent: {
    padding: "0!important",
    margin: 0
  },
});

const Integrations = (props) => {
  const { classes } = props;

  const dispatch = useDispatch();

  const [filteredClasses, setFilteredClasses] = useState({});
  const [loading, setLoading] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const integrations = useSelector((state) => state.userIntegrations.platforms);
  const integrationData = useSelector((state) => state.userIntegrations.platformData);
  const updated = useSelector((state) => state.userIntegrations.integrationsUpdated);
  const result = useSelector((state) => state.userIntegrations.result);
  const deviceModelData = useSelector((state) => state.device.deviceModelData);
  const modelClasses = useSelector((state) => state.model.modelClasses);

  const snackbarMessageController = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }

  useEffect(() => {
    dispatch(fetchUserIntegrations());
  }, []);

  useEffect(() => {
    if (updated) {
      dispatch(fetchUserIntegrations());
    }
  }, [updated]);

  useEffect(() => {
    if (result && result !== "BEGINNING") {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [result]);

  useEffect(() => {
    const algSet = new Set([...deviceModelData.map((data) => data.alg_id)]);
    for (const algId of [...algSet]) {
      if (!(algId in modelClasses)) {
        dispatch(fetchModelClasses(algId));
      }
    }
  }, [deviceModelData])

  useEffect(() => {
    if (deviceModelData && modelClasses) {
      const updatedClasses = {}
      const algIds = deviceModelData.map((model) => model.alg_id.toString());
      for (const key of Object.keys(modelClasses)) {
        if (algIds.includes(key)) {
          updatedClasses[key] = modelClasses[key];
        }
      }
      setFilteredClasses(updatedClasses);
    }
  }, [modelClasses]);

  const items = [
    (<IntegrationItem 
      icon={(<RouterIcon/>)} 
      platform={IntegrationPlatforms.GUNDI} 
      text="Gundi" 
      loading={loading} 
      availableClasses={filteredClasses} 
      integrationData={integrationData.gundi}
      hasIntegration={integrations.includes(IntegrationPlatforms.GUNDI)}
    />),
    (<IntegrationItem 
      icon={(<MailIcon/>)} 
      key="email_integration"
      platform={IntegrationPlatforms.EMAIL}
      text="Email" 
      loading={loading}
      availableClasses={filteredClasses} 
      integrationData={integrationData.email}
      snackbarMessageController={snackbarMessageController}
      hasIntegration={integrations.includes(IntegrationPlatforms.EMAIL)}/>),
    (<IntegrationItem 
      icon={(<WifiIcon/>)} 
      key="sms_integration"
      platform={IntegrationPlatforms.SMS}
      text="SMS" 
      loading={loading}
      availableClasses={filteredClasses} 
      integrationData={integrationData.sms}
      snackbarMessageController={snackbarMessageController}
      hasIntegration={integrations.includes(IntegrationPlatforms.SMS)}/>),
  ];

  return (
    <React.Fragment>
      <Card className={classes.integrationInfoCard}>
        <CardContent className={classes.cardContent}>
          <ItemList items={items} />
        </CardContent>
      </Card>
      <RequestSnackbar name="Integration" baseSelector="userIntegrations"/>
      <ErrorSnackbar message={snackbarMessage} snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen}/>
    </React.Fragment>
  );
};

export default withStyles(styles)(Integrations);
