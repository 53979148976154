import { Box, Checkbox, Chip, ListItemText, MenuItem, TextField, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { NotificationConstants } from '../../../util/constants';

// Style
const styles = (theme) => ({
  filterContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  chipBox: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,
    padding: 0
  },
  chip: {
    margin: '4px'
  },
  checkbox: {
    paddingLeft: 0
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  getContentAnchorEl: () => null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
    },
  },
};

const NotificationSection = (props) => {
  const { classes, notificationClasses, availableClasses, onNotificationChange } = props;
  const [selectedClasses, setSelectedClasses] = useState(notificationClasses.filter((c) => c !== NotificationConstants.NONE));

  const getSearchableClasses = () => {
    let uniqueClassTax = new Set();
    if (availableClasses) {
      for (const k of Object.keys(availableClasses)) {
        for (const d of availableClasses[k]) {
          if (d.original_label) uniqueClassTax.add(d.original_label);
        }
      }
      uniqueClassTax = [...uniqueClassTax].sort();
    }
    return [
      ...uniqueClassTax
    ];
  };

  const handleSelectedClassChange = (newValue) => {
    setSelectedClasses(newValue);
    onNotificationChange(newValue);
  };

  const handleCheckChange = (event) => {
    const checked = event.target.checked;
    if (checked) {
      setSelectedClasses([NotificationConstants.ALL]);
      onNotificationChange([NotificationConstants.ALL]);
    } else {
      setSelectedClasses([]);
      onNotificationChange([]);
    }
  }
    
  return (
    <div className={classes.filterContainer}>
      <Typography className={classes.title} variant="subtitle1" noWrap>Notification Settings</Typography>
      <div className={classes.checkboxContainer}>
        <Checkbox
          className={classes.checkbox}
          checked={selectedClasses.includes(NotificationConstants.ALL)}
          onChange={handleCheckChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Typography variant='body1'>Notify for all Class Names</Typography>
      </div>
      <p>Notify on specific Class Names:</p>
      <TextField
        select
        label="Class Name"
        value={selectedClasses.filter((className) => className !== NotificationConstants.ALL)}
        SelectProps={{
          multiple: true,
          onChange: (e) => handleSelectedClassChange(e.target.value),
          MenuProps: MenuProps,
          renderValue: (selected) => (
            <Box className={classes.chipBox}>
              {selected.map((value) => (
                <Chip className={classes.chip} key={value} label={value} />
              ))}
            </Box>
          )
        }}
        variant="outlined"
        >
        {getSearchableClasses()
        .map((className) => (
          <MenuItem key={className} value={className}>
              <Checkbox checked={selectedClasses.indexOf(className) > -1} />
              <ListItemText primary={className} />
            </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default withStyles(styles)(NotificationSection);
