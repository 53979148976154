import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox, Grid, Select, TextField, Typography, makeStyles } from '@material-ui/core';
import { createDeviceUpdate, fetchDeviceUpdates } from '../../actions/deviceUpdateActions';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { fetchDevice } from '../../actions/deviceActions';


const useStyles = makeStyles({
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    paddingLeft: 0
  }
});


export default function CreateUpdateModal(props) {
  const { allReleases, allDeviceIds } = props;
  const [deviceIds, setDeviceIds] = useState([]);
  const [firmwareReleaseTag, setFirmwareReleaseTag] = useState("release");
  const [algReleaseId, setAlgReleaseId] = useState(null);
  const [algReleaseMap, setAlgReleaseMap] = useState({});
  const [cycleTime, setCycleTime] = useState("690");
  const [triggerNumber, setTriggerNumber] = useState("30");
  const [satelliteFixTime, setSatelliteFixTime] = useState("5");
  const [satellitePasses, setSatellitePasses] = useState("");
  const [errorText, setErrorText] = useState("");
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSave = async () => {
    setErrorText([]);
    if (deviceIds.length === 0 || !algReleaseId || !cycleTime || !triggerNumber || !firmwareReleaseTag) {
      setErrorText(["Please fill in all required fields."]);
      return;
    }
    const response = await createDeviceUpdate({
      deviceIds: deviceIds.map((deviceId) => parseInt(deviceId)),
      firmwareReleaseTag,
      algReleaseId: algReleaseMap[algReleaseId]?.id,
      settings: {
        cycleTime: parseInt(cycleTime),
        triggerNumber: parseInt(triggerNumber),
        satelliteFixTime: parseInt(satelliteFixTime),
        satellitePasses
      }
    });
    if (response?.status) {
      dispatch(fetchDeviceUpdates());
      dispatch(fetchDevice());
      setOpen(false);
    } else {
      if (response?.errorCode === 400) {
        setErrorText([
          "One or more of the selected devices already have a pending update. You will need to drop the existing pending update to create a new update. To do this, click on the update with the pending status in the Device Updates tab and click \"Drop\". If you are not the owner of the existing pending update, contact the owner to drop the existing pending update.",
          "----",
          "Conflicting Updates:",
          ...response?.errorMessage?.updates.map((update) => `Username: ${update.email} - Update ID: ${update.updateId}`)
        ]);
      } else {
        setErrorText(["An unknown error has occurred. Contact sentinel-support for more information."]);
      }
    }
  }

  useEffect(() => {
    if (allReleases) {
      const newReleaseMap = {};
      for (const release of allReleases) {
        newReleaseMap[`${release.id} - ${release.name}`] = release;
      }
      setAlgReleaseMap(newReleaseMap);
    }

  }, [allReleases])

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        Create Update
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          Create Update
        </DialogTitle>
        <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete 
              multiple
              onChange={(event, value) => setDeviceIds(value)}
              value={deviceIds}
              options={allDeviceIds}
              renderInput={(params) => <TextField required {...params} label="Device IDs" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete 
              onChange={(event, value) => setAlgReleaseId(value)}
              value={algReleaseId}
              options={Object.keys(algReleaseMap)}
              renderInput={(params) => <TextField required {...params} label="Alg Release ID" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete 
              onChange={(event, value) => setFirmwareReleaseTag(value)}
              value={firmwareReleaseTag}
              options={["release", "alpha"]} // basheim
              renderInput={(params) => <TextField required {...params} label="Firmware Tag - Release = Tested Version, Alpha = Experimental Version" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete 
              onChange={(event, value) => setTriggerNumber(value)}
              value={triggerNumber}
              options={["10", "30", "50"]}
              renderInput={(params) => <TextField required {...params} label="Photo Count Trigger" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete 
              onChange={(event, value) => setCycleTime(value)}
              value={cycleTime}
              options={["390", "690"]}
              renderInput={(params) => <TextField required {...params} label="Cycle Time in Minutes" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete 
              onChange={(event, value) => setSatelliteFixTime(value)}
              value={satelliteFixTime}
              options={["2","3","4","5"]}
              renderInput={(params) => <TextField {...params} label="Satellite Fix Time" />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              inputProps={{
                maxLength: 100
              }}
              margin="dense"
              label="Satellite Passes"
              name="description"
              value={satellitePasses}
              onChange={(event) => setSatellitePasses(event.currentTarget.value)}
            />
          </Grid>
        </Grid>
        {
          errorText && errorText.map((textLine) => {
            return (<Typography color="secondary">{textLine}</Typography>);
          })
        }
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={handleSave}
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
